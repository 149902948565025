import React, { useState } from "react"
import BlockWrapper from "../technical/BlockWrapper"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

import { useTranslation } from "react-i18next"

const FilteredGalleryBlock = ({ block }) => {
  const [usedFilter, setUsedFilter] = useState("all")
  const [filteredResult, setFilteredResult] = useState(
    block.filtered_gallery_items
  )
  const { t } = useTranslation()

  // Collect filters
  let filters = []
  for (let i = 0; i < block.filtered_gallery_items.length; i++) {
    console.log(block.filtered_gallery_items[i].filter)
    if (filters.indexOf(block.filtered_gallery_items[i].filter) === -1) {
      filters.push(block.filtered_gallery_items[i].filter)
    }
  }
  // Show all
  const unfiltering = () => {
    setUsedFilter("all")
    setFilteredResult(block.filtered_gallery_items)
  }
  // Show filtered results
  const filtering = (filter) => {
    setUsedFilter(filter)
    setFilteredResult(
      block.filtered_gallery_items.filter((item) => item.filter === filter)
    )
  }

  return (
    <BlockWrapper block={block} blockPadding="py-20">
      <Richtext className="mt-8" text={block.text} />
      <div className="mt-8 -mb-4 -ml-4">
        <div
          className={`ml-4 mb-4 border ${
            usedFilter === "all"
              ? "bg-primary text-white border-primary pointer-events-none"
              : "border-black"
          } btn hover:cursor-pointer hover:bg-primary-hover hover:border-primary-hover hover:text-white`}
          onClick={() => unfiltering()}
        >
          {t("generic.new_flag.new_article")}
        </div>
        {filters.map((filter, index) => (
          <div
            className={`ml-4 mb-4 border ${
              usedFilter === filter
                ? "bg-primary text-white border-primary pointer-events-none"
                : "border-black"
            } btn hover:cursor-pointer hover:bg-primary-hover hover:border-primary-hover hover:text-white`}
            onClick={() => filtering(filter)}
            key={index}
          >
            {filter}
          </div>
        ))}
      </div>
      <div className="flex flex-row flex-wrap mt-8 -mb-10 -mx-half-grid">
        {filteredResult?.map((item, index) => (
          <div className="w-full mb-10 md:w-1/2 lg:w-1/3 px-half-grid">
            <Link className="group" link={item.link}>
              <Image
                className="w-full h-auto"
                image={item.image}
                forceLoad={true}
                aspectRatio="4by3"
              />
              <span className="block mt-6 transition-colors ease-in-out duration-500 group-hover:text-primary">
                {item.title}
              </span>
            </Link>
          </div>
        ))}
      </div>
    </BlockWrapper>
  )
}

export default FilteredGalleryBlock
